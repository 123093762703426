@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@200;300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pattaya&display=swap");


@font-face {
  font-family: Avant Garde;
  src: url("./font/texgyreadventor-regular.otf") format("opentype");
}

.App {
  text-align: center;
  background-color: #ffffff;
  min-height: 100vh;
  /* font-family: Gothic A1; */
  /* font-family: 'Questrial', sans-serif; */
  /* font-family: Avant Garde; */
  font-family: Montserrat;
  font-family: Pacifico;
  font-family: Questrial;

  color: black;
  scroll-behavior: smooth;
  font-weight: 100;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h2, h1 {
  font-family: Questrial;
}

img {
  -webkit-user-select: none;  /* Chrome all / Safari all */
-moz-user-select: none;     /* Firefox all */
-ms-user-select: none;      /* IE 10+ */
-o-user-select: none;
user-select: none;
pointer-events: none;
}

.bold { font-weight: 500; margin: 0.6em 0; }

.page {
  position: absolute;
  top: 160px;
  left: 0;
  width: 100%;
  margin-top: 1.2em;
}


.home-container {
  
  min-height: calc( 100vh - 160px - 210px );
}

.home-container.about {
  font-size: 1.2em;
  padding: 1em 4em;
  padding-top: 3em;
}

.home-container.about h2 {
  margin-bottom: 1.5em;
}

.home-container.about p {
  margin-bottom: 2em;
  text-align: left;

}


.navbar-socials-mobile {
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 50px 50px 50px;

}

.load-more-button {
  background-color: rgba(231, 209, 255, 0.3);

}

.load-more-button:hover {
  background-color: rgba(231, 209, 255, 0.8);
  transform: scale(1.1);
}

.footer {
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 0em;
  padding: 4em 4em;
  margin-top: 50px;
  background-color: rgba(231, 209, 255, 0.4);
}

.footer-item {
  /* display: flexbox; */
  margin: 0;
  width: 33%;

}

.dropdown-toggle:after { content: none }

header.header {
  /* position: sticky; */
  width: 100%;
  top: 0;
  z-index: 100;
  height: auto;
  padding: 1em 2em 0 2em;
  background-color: rgba(231, 209, 255, 0.8);
  margin-bottom: 1.5em;
  /* border-bottom: 3px solid black; */
  /* background-color: rgb(209, 212, 255); */
  /* background-color: rgb(247, 249, 240); */
}

@media only screen and (min-width: 1080px) {
  header.header {
    padding: 1em 4em 0 4em;
  }
}

.header-top {
  display: flex;
  align-items: center;
}
.header-top .social-icon {
  font-size: 1.3em;
  padding: 0 1em;
}
.header-top h2 {
  /* font-family: Montserrat; */
  font-family: Pattaya;
  font-size: 4em;
  font-weight: 100;
}


.navlinks                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-family: Questrial;
  padding-bottom: 1em;

}

.navlink {
  text-decoration: none;
  color: black;
  padding: 0.78em 1em;
  text-align: center;
  font-size: 1.3em;
  display: inline;
  position: relative;
  border: 0;
  min-width: 120px;
  width: 100%;
  user-select: none;
}

.menu-item.navlink {
  width: auto;
  min-width: 0;
  outline: 0;
  font-family: Montserrat;
  font-weight: 300;
}

.menu-item.navlink.bold {
    font-family: Montserrat;
    letter-spacing: 1px;
    font-weight: 500;
}

.menu-item.navlink.sub.active, .menu-item.navlink.sub:hover {
  border-radius: 30px;
  opacity: 0.5;
}



.bm-item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navlink:focus {
  user-select: none !important;
}


.navlink.active {
  background-color: white;
  border-radius: 20px 20px 0 0;
  /* border-bottom: 2px solid white; */
}

.no-results {
  margin: 0 auto;
  margin-top: 5em !important;
  font-size: 2em;
  font-family: Pattaya;
  text-align: center;
  font-weight: 100;
}

.navlink:hover {
  transition: 300ms;
  color: grey;
  text-decoration: none;
}

.navlink.sub {
  display: inline-block;
}

.navlink.sub:hover {
  color: grey;
  transition: 300ms;
}

.navbar-socials .social-icon {
  padding: 0.5em;
}

.socials {
  margin: 0 auto;
  margin-top: 0.5em;
  height: auto;
  width: 80%;
}

.socials .social-icon {
  color: rgb(83, 89, 97);
  padding: 0.2em 0.3em;
  position: relative;
  display: inline-block;
  width: 20%;
}


.social-icon {
  text-decoration: none;
  color: black;
  padding: 0.3em;
  transition: 300ms;
}


.social-icon:hover {
  transition: 300ms;
  color: pink;
}


.ui.card>.content>.header, .ui.cards>.card>.content>.header {
  font-family: Pacifico;

}

.ui.container.home-container {
  margin: 1em 12vw 0 12vw !important;
}


.navlink.sub:hover, .navlink.sub.active {
    background-color: lightgrey;
    color: black;
    border-radius: 0;
}


.ui.card>:first-child, .ui.cards >.card > :first-child {
  border-radius: 0 !important;
}

.ui.three.doubling.cards{
  margin: 0 auto;
}

.blog-post-cover {
  max-width: fit-content;
  margin: 0 auto;
  border: 0 !important;
}

.blog-text p {
  font-size: 0.8em;
  text-align: left;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
}

/* .blog-text p * {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.blog-text h2 {
  text-decoration: double;
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.blog-text p img {
  transform: scale(0.9);
  justify-self: center;
}

@media only screen and (max-width: 1365px) {
  .blog-text p {
    font-size: 0.8em;
    display: block;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  p {
    font-size: 0.9em;
  }

  .blog-text p {
    font-size: 0.7em;
    display: block;
    text-align: left;
  }
}

p strong {
  display: flex;
  justify-content: center;
  text-align: center;

}

.blog-card .content {
  border: 2px solid black !important;
  
}





.blog-card-content {
  box-shadow: 0px 8px 12px -2px rgba(0,0,0,0.8) !important;
  -webkit-box-shadow: 0px 8px 12px -2px rgba(0,0,0,0.48) !important;
  -moz-box-shadow: 0px 8px 12px -2px rgba(0,0,0,0.48) !important;

  /* box-shadow: 0px 13px 31px -2px rgba(0,0,0,0.82);
-webkit-box-shadow: 0px 13px 31px -2px rgba(0,0,0,0.82);
-moz-box-shadow: 0px 13px 31px -2px rgba(0,0,0,0.82); */

  background-color: white;
  z-index: 2;
}

.blog-card-content p {
  font-size: 0.75em;
}

.map-text {
  padding: 3em 10em;
  font-size: 1.2em;
}

@media only screen and (max-width: 1024px) {
  .map-text {
    padding: 3em 3em;
  }

}



@media only screen and (min-width: 1200px) {
  /* .blog-card-content {
    width: 22vw;
  } */
  .blog-card-content .ui.grid {
    padding: 0em 1em !important;
  }

  .map {
    height: 75vh;
  }
}

@media only screen and (max-width: 1200px) {

  .blog-card-content p {
    font-size: 0.6em;
  }
  
}

.card.blog-card {
  /* max-width: fit-content !important; */
  transition: 300ms;
}

.card.blog-card:hover {
  transition: 300ms;
  transform: scale(1.05);
  
}

.card .reveal:hover {
  transform: scale(1);

}


.ui.card, .ui.cards>.card {
  box-shadow: none;
}


.reveal {
  transition: 300ms;
  
}

.reveal:hover {
  transition: 300ms;
  transform: scale(1.03);
  cursor: pointer;
}

.collage {
  transform: scale(0.9);
  transition: 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 467px; */
}

.collage:hover {
  transition: 300ms;
}


@media only screen and ( max-width: 520px) {
  .collage{
    transform: scale(1.6);
    margin-bottom: 5em !important;
    margin-top: 6em !important;
  }
}



.ui.text.vertical.menu .item:hover {
  color: black;
  font-weight: bold;
  transition: 600ms;
}





@media only screen and ( max-width: 1024px) {
  .page {
    top: 120px;
  }

  .blog-contents {
    padding: 0 0.75em;
  }

  .ui.menu {
    margin: 0;
  }

  .ui.vertical.menu {
    width: auto;
    margin: 0 !important;
  }

  .ui.text.vertical.menu {
    margin-right: 0 !important;
  }

  .item {
    text-align: center;
    width: 100%;
    transition: 600ms;

  }

}


@media only screen and ( max-width: 767px) {

  .about {
    padding: 0 !important;
  }

  a.item {
    width: 50%;
    float: left;
    border-right: 1px solid rgba(0,0,0,0.3) !important;
    padding-right: 5px !important;
    /* border-bottom: 1px solid rgba(0,0,0,0.3); */
  }

  a.item:nth-child(odd) {
    border-right: none !important;
    padding-right: 0 !important;
    padding-left: 20px !important;
  }

  a.ui.card {
    margin: 5px !important;
  }
  
  .ui.four.doubling.cards > .card {
    width: calc( 50% - 1em );
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 2em 0em;
  }

  .footer-item {
    /* display: flexbox; */
    width: 33%;
    margin: 0 0.8em;
  }
}

.infographic-section {
  display: flex;
  justify-content: center;
  transform: scale(1.1);
}

.infographic-img {
  margin: 1em 0;
  /* min-width: 80px !important; */
  width: 100px !important;
  height: 100px !important;
  max-width: 100px !important;
  transition: 400ms;
}

.infographic-icon {
  /* min-width: 120px; */
  width: 100px !important;
  margin: 0em 0.3em;
  margin-bottom: 1em !important;
}

.infographic-text {
  font-size: 0.75em;
  margin-bottom: 0.5em;
  font-weight: 600;
}

.infographic-details {
  font-size: 0.6em;
}

.infographic-icon:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 520px) {
  .infographic-section {
    transform: translateY(-0.75em) scale(0.72) ;
  }

  .external-blog-links {
    transform: translateY(-1.25em);
  }

  .blog-contents h1 {
    margin-bottom: 0 !important;
  }

}

@media only screen and (max-width: 350px) {
  .infographic-section {
    transform: translateY(-0.75em) scale(0.65);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .infographic-section {
    transform: scale(0.9);
  }
}

.external-blog-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.8em;
}

@media only screen and (max-width: 767px) {

  .external-blog-links {
    font-size: 0.7em;
  }
}

.blog-url {
  display: block;
  width: 100%;
  margin-bottom: 1em;
  color: rgb(182, 164, 201);
}

.blog-url.branches {
  /* text-decoration: underline; */
}

.blog-url a {
  color: rgb(182, 164, 201);
  text-decoration: underline;


}

.blog-url.website a {
  margin-left: 0.1em;
  transition: 400ms;
}

.blog-url a:hover {
  transition: 400ms;
  color: rgb(129, 115, 145);
}

.branch-location {
  margin-top: 1em !important;
  margin-bottom: 0.2em !important;

}

.info-section {
  /* margin-bottom: 6em; */
  min-height: 550px;
}

.mobile-blog-separator {
  margin-bottom: 1.5em;
  margin-top: 0em;
  background-color: rgba(231, 209, 255, 0.8);
  height: 2px;
  border: 0px;
}

@media only screen and (max-width: 1024px) {
  .info-section {
    min-height: 450px;
  }

  .blog-contents {
    padding-top: 0.75em !important;
  }

}
