.searchbar-input {
  font-family: Questrial;
  font-size: 1.5em;

}

.searchbar-input:focus {
  outline: none;
}

.search-close {
  cursor: pointer;
  font-weight: 1000;
  font-size: 1.5em;
}